import 'antd/dist/reset.css';
import './asset/style/App.scss';
import {Route, Routes} from "react-router-dom";
import SignIn from "./page/SignIn";
import NoMatch from "./page/NoMatch";
import DashBoard from "./page/dashBoard/DashBoard";
import RequireAuth from "./auth/RequireAuth";
import AuthProvider from "./auth/AuthContext";
import ToastProvider from "./page/components/ToastProvider";
import NewPassword from "./page/NewPassword";
import Home from "./page/Home";
import RequestPassword from "./page/RequestPassword";
import TranslateProvider from "./utils/TranslateProvider";

function App() {
  return (
    <div className="App d-flex flex-column justify-content-between h-100">
        <AuthProvider>
            <TranslateProvider>
            <ToastProvider>
                <Routes>

                    <Route path="*" element={<NoMatch />} />
                    <Route  path="/" element={<Home />} >
                        <Route index path="SignIn" element={<SignIn />} />
                        <Route path="new-password/:token" element={<NewPassword />} />
                        <Route path="request-new-password" element={<RequestPassword /> } />
                    </Route>


                    <Route
                        path="dashboard"
                        element={
                            <RequireAuth>
                                     <DashBoard />
                            </RequireAuth>

                    }
                    />
                </Routes>
            </ToastProvider>
            </TranslateProvider>
        </AuthProvider>
    </div>
  );
}

export default App;
