import React, {memo, useContext} from "react";
import useAuth from "../../auth/useAuth";
import {useNavigate} from "react-router-dom";
import {
    PoweroffOutlined,
    UserOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import {Avatar, Button, Dropdown, Switch, Tooltip} from "antd";
import type { MenuProps } from 'antd';
import { Space, Typography } from 'antd';
import {CustomThemeContext} from "../../auth/CustomThemeProvider";
import {CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {TranslateContext} from "../../utils/TranslateProvider";
const { Text, Link } = Typography;


function UserNav(){
    let auth = useAuth();
    let navigate = useNavigate();

    const { theme, toggleTheme } = useContext(CustomThemeContext);
    const {t, getLanguageMenu} = useContext(TranslateContext);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a  target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    <UserOutlined /> {t('profile')}
                </a>
            ),
            disabled: true,
        },
        {
            key: '2',
            label: (
                <a  target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
                    <SettingOutlined /> {t('settings')}
                </a>
            ),
            disabled: true,
        },
        {
            key: '3',
            label: (
                <a target="_blank" onClick={()=>{
                    auth.signOut();
                    navigate("/");
                }} rel="noopener noreferrer" href="#">
                    <PoweroffOutlined /> {t('logout')}
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <>
                    <Text>{t('darkmode')} :</Text>
                    <Switch
                        label={t('darkmode')}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onClick={()=>{
                            toggleTheme();
                        }}
                    />
                </>
            ),
        },
        {
            key: '5',
            label: (
            <>
                {getLanguageMenu()}
            </>
            ),
        }

    ];





    return (
        <div>
           <Text>{t('welcome')} {auth.login} </Text>
            {/*<SettingOutlined />*/}
            <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <Avatar icon={<UserOutlined />} />
                {/*<Tooltip title="search">*/}
                {/*    <Button shape="circle" icon={<SearchOutlined />} />*/}
                {/*</Tooltip>*/}

            </Dropdown>


        </div>


    );
}

export default memo(UserNav);