import {useEffect, useState} from 'react';
import axios from 'axios';
import Resumable from 'resumablejs';

export default function useUpload(toast, t) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadProgressZIP, setUploadProgressZIP] = useState(0);
    const [cancelUpload, setCancelUpload] = useState(null);
    const [speed, setSpeed] = useState(0);
    const [error, setError] = useState(null);

    const [response, setResponse] = useState(null);
    const [zip, setZip] = useState(null);

    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [resumableInstance, setResumableInstance] = useState(null);

    const submitPDF = (formData, callbackSuccess, callbackError) => {
        setError(null)
        setUploadProgress(0)
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        setCancelUpload(() => source.cancel);
        let startTime = new Date().getTime(); // Save the start time



        axios.post(`${BASE_URL}/upload-pdf`, {pdf: formData.get('pdf'),reference:formData.get('reference')}, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            method: 'POST',
            withCredentials: true,
            cancelToken: source.token,
            onUploadProgress: function(progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);

                // Calculate the elapsed time
                let elapsedTime = new Date().getTime() - startTime; // Time elapsed in milliseconds

                // Calculate the average upload speed in MB/s
                let avgUploadSpeed = (progressEvent.loaded) / (elapsedTime / 1000) / (1024 * 1024); // bytes per second to MegaBytes per second

                setSpeed(avgUploadSpeed.toFixed(2));
            },
        })
            .then(response => {
                setUploadProgress(100);
                // Calculate final average upload speed
                let finalElapsedTime = new Date().getTime() - startTime;
                let finalAvgUploadSpeed = (response.data.size) / (finalElapsedTime / 1000) / (1024 * 1024); // assuming response.data.size is the total uploaded size in bytes

                console.log("Final average upload speed: " + finalAvgUploadSpeed.toFixed(2) + " MB/s");
                console.log(response.data);
                setResponse(response.data);
                //upload zip
                submitZIP(formData.get('zip'), response.data.path);


            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    toast.error(t('uploadCancel'), 15);
                } else {
                    toast.error(err.response.data.message, 15);
                    setError("exception");
                    console.log(err);
                }
                setUploadProgress(0);
                console.error(err);
            });
    }



    const submitZIP = (formData, finalPath) => {
         //upload zip with resumable
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setCancelUpload(() => source.cancel);

        let startTime = new Date().getTime(); // Save the start time

        const resumable = new Resumable({
            target: `${BASE_URL}/upload-large-file`, // URL NestJS backend
            chunkSize: 5 * 1024 * 1024,  // 5MB
            uploadMethod: 'POST',
            withCredentials: true,
            //send finalpatch
            query: {
                finalPath: finalPath,
            },
            simultaneousUploads: 3,
            testChunks: false,
        });

        setResumableInstance(resumable);

        resumable.on('fileProgress', (file) => {
            // set the upload progress with no decimal places
            setUploadProgressZIP(Math.round(resumable.progress()*100))
            // Calculate the elapsed time
            let elapsedTime = new Date().getTime() - startTime; // Time elapsed in milliseconds

            // Calculate the average upload speed in MB/s
            let avgUploadSpeed = (file.size) / (elapsedTime / 1000) / (1024 * 1024); // bytes per second to MegaBytes per second

            setSpeed(avgUploadSpeed.toFixed(2));
        });



        //addfile formdate.get('zip)
        resumable.addFile(formData);
        resumable.on('fileAdded', (file) => {
            resumable.upload();
        });

        resumable.on('error', (message, file) => {
            console.log(message);
            setError("exception")
           toast.error(message, 15);
        });

        resumable.on('fileSuccess', (file, message) => {
            setUploadProgressZIP(100);
            // Calculate final average upload speed
            let finalElapsedTime = new Date().getTime() - startTime;
            let finalAvgUploadSpeed = (file.size) / (finalElapsedTime / 1000) / (1024 * 1024); // assuming response.data.size is the total uploaded size in bytes

            console.log("Final average upload speed: " + finalAvgUploadSpeed.toFixed(2) + " MB/s");
            console.log(message, file);
            setResponse(message);
            setUploadSuccess(true);
        });




    }


    /**
     * handle cancel upload for both pdf and zip, it will call the new route to delete the remaining chunks on tmp folder on server
     * @param callback
     * @returns {Promise<void>}
     */
    const cancelUploadHandler = async (callback) => {
        if (cancelUpload) {
            cancelUpload();
        }
        if (resumableInstance && resumableInstance.files.length > 0) {

            const resumableFileIdentifier = resumableInstance.files[0].uniqueIdentifier;

            console.log(resumableFileIdentifier);

            resumableInstance.cancel();

            // Appeler la nouvelle route d'API pour supprimer les chunks restants
             // Obtenir le premier (et normalement seul) fichier de l'instance Resumable
            await axios.post(`${BASE_URL}/cancel-upload`, { resumableIdentifier: resumableFileIdentifier, ftpFolderPath: response.path }, {
                withCredentials: true,
            });

        }
        setUploadProgressZIP(0);
        setUploadProgress(0);
        setUploadSuccess(false)
        callback();
    }

    return [
        submitPDF,
        submitZIP,
        response,
        cancelUploadHandler,
        uploadProgress,
        cancelUpload,
        speed,
        uploadProgressZIP,
        error,
        uploadSuccess,
    ];
}
