import {useContext} from "react";
import {ToastContext} from "../app/page/components/ToastProvider";

export default function useCheckFile(t) {
    const toast = useContext(ToastContext);
    const MAX_FILE_SIZE = process.env.REACT_APP_MAX_FILE_SIZE;
    /**
     * check file name and extension
     * file name must be utf-8
     */
    const checkFileName = (file) => {
        const fileName = file.name;        ;
        const fileExtension = fileName.split('.').pop();
        const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
        const isUTF8 = /^[A-Za-z0-9 ._-]*$/.test(fileNameWithoutExtension);
        if (!isUTF8) {
            toast.error(t('fileNameRules'), 15)
            return false;
        }
        return isUTF8;
    }

    const checkFileSize = (file) => {


        console.log("controle taille fichier", file.size, MAX_FILE_SIZE);
        if (file.size > MAX_FILE_SIZE) {
            toast.error('File size must be less than 5GB', 15);
            return false;
        }
        return true;
    }


    return [checkFileName, checkFileSize];
}