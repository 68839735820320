
import logo from "../asset/img/logo/FTP_mastering.png";
import logoBlanc from "../asset/img/logo/FTP_mastering_blanc.png";
import {Typography} from "antd";
import React, {useContext, useEffect, useState} from "react";
import {Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../auth/useAuth";
import {ToastContext} from "./components/ToastProvider";
const { Text,  } = Typography;
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function Home(){

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    let toast = useContext(ToastContext);
    //import du theme
    const theme = JSON.parse(localStorage.getItem('darkMode') || "false");

    return (
        <div className="container-fluid h-100 d-flex flex-column justify-content-between">
            <div className="row vh-90 align-items-center">
                <div className="col-lg-6">
                </div>
                <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-center z-2">
                    {
                        theme ?
                            <img className="mb-5" width="400px" src={logoBlanc} alt="Logo SNA-GZ"/>
                            :
                            <img className="mb-5" width="400px" src={logo} alt="Logo SNA-GZ"/>
                    }

                    <Outlet/>
                </div>
            </div>
            <footer className="h-25">
                <Text style={{color:'white',fontWeight:100, opacity:0.8, letterSpacing:1}} type="secondary">Copyright © SNA-GZ {new Date().getFullYear()} - {process.env.REACT_APP_VERSION || '1.0.0'}</Text>
                <p className="fw-lighter fs-6"></p>
            </footer>
        </div>

    );
}