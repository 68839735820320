// @ts-ignore
import React, {useContext} from "react";
//import css
import './app/asset/style/App.scss';

// @ts-ignore
import ReactDOM from "react-dom/client";
import './app/asset/style/App.scss';
import {BrowserRouter} from "react-router-dom";
import App from "./app/App";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {CustomThemeProvider} from "./app/auth/CustomThemeProvider";


/**
 * THEME
 * @type {string}
 */


function MainApp() {

    return (
        <React.StrictMode>
            <BrowserRouter>
                <React.StrictMode>
                        <DevSupport
                            ComponentPreviews={ComponentPreviews}
                            useInitialHook={useInitial}
                        >
                            <App />
                        </DevSupport>
                </React.StrictMode>
            </BrowserRouter>
        </React.StrictMode>
    );
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <CustomThemeProvider>
        <MainApp />
    </CustomThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
