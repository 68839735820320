import React, {useContext, useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Row} from "antd";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../auth/useAuth";
import {ToastContext} from "./components/ToastProvider";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {TranslateContext} from "../utils/TranslateProvider";




export default function SignIn({user,from, error}) {

    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();
    let toast = useContext(ToastContext);
    const {t, getLanguageMenu } = useContext(TranslateContext);

    useEffect(() => {
        if (auth.user) {
            navigate('/dashboard');
        }
    }, [auth.user]);




    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const onFinish = async (values: any) => {
        setLoading(true)

        const response = await auth.signIn(
            values.username,
            values.password,
            () => {
                navigate(from || "/dashboard");
            },
            setLoading,
            values.remember,
            setErrorMessage);

    };


    let validateStatus = "";
    let helpMessage = "";
    if (errorMessage) {
        validateStatus = "error";
        helpMessage = errorMessage;
    }




    return (
                <>
                        <Form
                            name="normal_login"
                            className="login-form"
                            onFinish={onFinish}
                            rootClassName={"login-form "}
                        >
                            <Form.Item
                                name="username"
                                rules={[{ required: true, message: t('loginRules') }]}
                                validateStatus={validateStatus}
                                help={helpMessage}
                                status={errorMessage ? t('error') : ""}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('account')} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message:t('passwordRules') }]}
                                validateStatus={validateStatus}
                                status={errorMessage ? t('error') : ""}

                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder={t('password')}

                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>{t("rememberMe")}</Checkbox>
                                </Form.Item>

                                {/*<Link className="login-form-forgot" to={"/request-new-password"}>*/}
                                {/*    {t("forgotPassword")}*/}
                                {/*</Link>*/}
                            </Form.Item>

                            <Form.Item>
                                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                    {t("login")}
                                </Button>
                            </Form.Item>

                        </Form>
                    {getLanguageMenu('white')}
                </>

    );
}