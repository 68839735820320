import {memo, useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import useAuth from "../app/auth/useAuth";


const cache = new Map();



function useFetch(url, filter, page = null, pageSize = null, cacheMode=false) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState("");
    const [firstPage, setFirstPage] = useState("");
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setupRequestInterceptor(setProgress);
        setupResponseInterceptor(setProgress);
    }, [url, filter]);

    let params = {};
    params =Object.assign({
        withCredentials: true,
        method: 'GET',
        skip: page,
        take: pageSize,
        url: url,
        params: {...filter, skip: page, take: pageSize},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }, params);


    const fetchData = () =>{
        //if the url and filter are the same, we don't need to fetch again
        const cacheKey = JSON.stringify({url, filter, page, pageSize});

        if (cache.has(cacheKey) && cacheMode) {
            setData(cache.get(cacheKey));
            setLoading(false);
            return;
        }

        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setHasMore(true);
        setLoading(true);
        setError(false);

        axios(params, {
            cancelToken: source.token,
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(percentCompleted);
            },
        }).then(response => {

            // setData(prevData => {
            //     return [...new Set([...prevData, ...response.data['hydra:member']])]
            // });

            setData([])
            setData(response.data)

            console.log(response.data)
            // Cache the fetched data


            if (data.length >= response.data['hydra:totalItems']) {
                setHasMore(false);
            }
            setLoading(false);
            cache.set(cacheKey, response.data);

            if (response.data.count > 0) {
                console.log('cache possible')
            }


        }).catch(e => {
            if (axios.isCancel(e)) return;
            setLoading(false);
            setError(true);
            setHasMore(false);

        });

        // return () => cancel();
        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }


    useEffect(() => {
        fetchData();
    }, [url, filter, page, pageSize]);

    return [loading, error, data, hasMore, metadata, progress, fetchData];
}



export default useFetch;

function setupRequestInterceptor(setProgress) {
    axios.interceptors.request.use((config) => {
        setProgress(0);
        return config;
    });
}

function setupResponseInterceptor(setProgress) {
    axios.interceptors.response.use(
        (response) => {
            setProgress(100);
            return response;
        },
        (error) => {
            setProgress(100);
            return Promise.reject(error);
        }
    );
}