import React, {memo} from "react";
import {Typography} from "antd";
import UserNav from "../components/UserNav";
import {Header} from "antd/es/layout/layout";
import {COLOR} from "../../auth/CustomThemeProvider";
import SigleSNA from "../../asset/img/logo/Sigle_SVG.svg";
import UploadForm from "../uploadComponent/UploadForm";


const {Text} = Typography;


function DashBoard() {

    const headerStyle = {
        textAlign: "end",
        color: COLOR["TEXT_COLOR"],
        backgroundColor: "transparent",
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-between">
                <div className="col d-flex flex-column vh-100 overflow-y-auto">
                    <Header className="mt-2 d-flex flex-row justify-content-between ps-2 align-items-center" style={headerStyle}>
                        <img width={50}
                             height={50}
                             src={SigleSNA}
                             alt=""
                        />
                        <UserNav />
                    </Header>
                    <div className="h-75 d-flex justify-content-center align-items-center" >
                        <UploadForm />
                    </div>

                </div>

            </div>
            <footer  className="position-fixed bottom-0 w-100">
                <Text className="text-white"  style={{fontWeight:300,opacity:0.8, letterSpacing:1}} type="secondary" >Copyright © SNA-GZ {new Date().getFullYear()} - {process.env.REACT_APP_VERSION || '1.0.0'}</Text>
                <p className="fw-lighter fs-6"></p>
            </footer>
        </div>
    );
}

export default memo(DashBoard);
