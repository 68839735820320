import React, {useState, createContext, useRef, useEffect} from 'react';
import {ConfigProvider, theme} from "antd";

export const CustomThemeContext = createContext();

export const COLOR = {
    MAIN_COLOR: '#ee7f59',
    DARK_BLUE: '#262F3F',
    TEXT_COLOR: '#C7D5EC',
    TEXT_COLOR_LIGHT: '#171717',
}

export const lightTheme = {
    token: {
        fontWeightStrong: 300,
        fontFamily: "Roboto",
        fontSize: 14,
        colorText: COLOR.TEXT_COLOR_LIGHT,
        colorError: "#ee7f59",
        wireframe: true,
        borderRadius: 16,
        colorPrimary: "#ee7f59",
        colorPrimaryBg: "#ea997d",
        colorPrimaryText: COLOR.TEXT_COLOR_LIGHT,
        colorPrimaryTextActive: "#282828",
        colorPrimaryActive: "#ee7f59",
        colorBgLayout: "#ffffff",
        colorBgContainer: "#ffffff",
        colorBorder: "#ee7f59",
        colorBgElevated: "rgba(255, 255, 255, 0.04)"
    },
    components: {
        Typography: {
            colorText: COLOR.MAIN_COLOR,
            colorTextHover: COLOR.MAIN_COLOR,
            colorTextActive: COLOR.MAIN_COLOR,
        },
        Badge: {
            colorError: COLOR.MAIN_COLOR,
            colorSuccess: COLOR.MAIN_COLOR,
            colorWarning: COLOR.MAIN_COLOR,
            colorInfo: COLOR.MAIN_COLOR,
            colorBgContainer: COLOR.TEXT_COLOR_LIGHT,
        },
        Icon: {
            fontsize: 20,
            size: 20,
        },
        Input: {
            colorInfoTextActive: COLOR.MAIN_COLOR,
            colorErrorText: COLOR.MAIN_COLOR,
        },
    },

}

export const darkTheme = {
    token: {
        fontWeightStrong: 300,
        fontFamily: "Roboto",
        fontSize: 14,
        colorText: "#c7d5ec",
        colorError: "#ee7f59",
        wireframe: true,
        borderRadius: 16,
        colorPrimary: "#ee7f59",
        colorPrimaryBg: "#ea997d",
        colorPrimaryText: "#ffffff",
        colorPrimaryTextActive: "#ffffff",
        colorPrimaryActive: "#ee7f59",
        colorBgLayout: "linear-gradient(50deg, rgb(74, 92, 119) 0%, rgb(38, 44, 58) 100%)",
        colorBgContainer: "rgba(255, 255, 255, 0.00)",
        colorBorder: "#ee7f59",
        colorBgElevated: "rgba(255, 255, 255, 0.04)"
    },
    components: {
        Typography: {
            colorText: COLOR.MAIN_COLOR,
            colorTextHover: COLOR.MAIN_COLOR,
            colorTextActive: COLOR.MAIN_COLOR,
        },
        Badge: {
            colorError: COLOR.MAIN_COLOR,
            colorSuccess: COLOR.MAIN_COLOR,
            colorWarning: COLOR.MAIN_COLOR,
            colorInfo: COLOR.MAIN_COLOR,
            colorBgContainer: COLOR.TEXT_COLOR,
        },
        Icon: {
            fontsize: 20,
            size: 20,
        },
        Input: {
            colorInfoTextActive: COLOR.MAIN_COLOR,
            colorErrorText: COLOR.MAIN_COLOR,
        },
    },
    algorithm: theme.darkAlgorithm,
}

export const CustomThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState({});


    useEffect(() => {
        const darkMode = localStorage.getItem('darkMode');

        console.log('darkMode', darkMode);

        if (darkMode === 'true') {
            setTheme(darkTheme);
            document.body.classList.remove('theme-light');
            document.body.classList.add('theme-dark');
        } else {
            setTheme(lightTheme);
            document.body.classList.remove('theme-dark');
            document.body.classList.add('theme-light');

        }

    }, [theme]);


    const getTheme = () => {
        const darkMode = localStorage.getItem('darkMode');
        if (darkMode === 'true') {
            return 'dark';
        } else {
            return 'light';
        }
    }

    const toggleTheme = () => {
        setTheme(theme === lightTheme ? darkTheme : lightTheme);
        localStorage.setItem('darkMode', theme === lightTheme ? 'true' : 'false');
    };


    return (
        <CustomThemeContext.Provider value={{theme,toggleTheme, getTheme}}>
            <ConfigProvider theme={theme}>
                     {children}
            </ConfigProvider>
        </CustomThemeContext.Provider>
    );
};
