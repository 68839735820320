import {createContext, useEffect, useState} from "react";
import jwtDecode from 'jwt-decode';
import {useLocation, useNavigate} from "react-router-dom";
import useAxiosInterceptor from "./Interceptor/useAxiosInterceptor";
import {ReactNode} from "react";

export let AuthContext = createContext(null);
const BASE_URL = process.env.REACT_APP_BASE_URL;



export default function AuthProvider({ children }: { children: ReactNode}) {
    let location = useLocation();
    let navigate = useNavigate();
    //get user from cookie jwt token

    let [sessionExpired, setSessionExpired] = useState(false);

    let getRole = () => {
        let token = localStorage.getItem("user");
        const decodedToken = jwtDecode(token);
        return decodedToken['roles'];
    };

    let getUser = () => {
        let token = localStorage.getItem("user");
        return jwtDecode(token);
    };

    let getLogin = () => {
        let token = localStorage.getItem("user");
        const decodedToken = jwtDecode(token);
        return decodedToken['Login'];
    }

    let [user, setUser] = useState(localStorage.getItem("user"));
    let [role, setRole] = useState(null);
    let [login, setLogin] = useState(null);


    useEffect(() => {
        console.log(location)
        //si la location contain /new-password/ or /request-new-password
        if(location.pathname.includes('/new-password/') || location.pathname.includes('/request-new-password')){
            console.log('new password')
        }else if(user){
            navigate('/dashboard');
        }else if(location.pathname === "/"){
            navigate('/signin');
        }
      }, [user]);


    useEffect(() => {

        if(location.pathname.includes('/new-password/') || location.pathname.includes('/request-new-password')) {
            return;
        }
        let token = localStorage.getItem("user");

        //test the FTP connection
         testFTPConnection()
            .then(testFTP => {
                if(!testFTP){
                    signOut(() => navigate('/SignIn'));
                }
            })
            .catch(e => console.error('Error testing FTP connection', e));

        if (token) {
            let decodedToken;
            try {
               decodedToken = jwtDecode(token);
            }catch (e) {
               signOut(() => navigate('/SignIn'))
            }

            setRole(getRole())
            setLogin(getLogin())

            if (decodedToken.exp * 1000 < Date.now()) {
                signOut(() => navigate('/SignIn'))
            } else {
                setUser(token);
            }
        }
    } ,[]);


    const authUrl = BASE_URL + "/auth";

    let signIn = async (newUser: string, password: string, callback: VoidFunction, setLoading, remember, setError) => {

        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL}/auth`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: newUser,
                    password: password,
                    remember: remember,
                    secure:false,
                }),
                credentials: 'include'
            });
            if (!response.ok) {
                setError("Login or password incorrect");
                throw new Error("Login or password incorrect");
            }

            const ftpTest = await testFTPConnection();

            if (!ftpTest) {
                setError('An error occurred while connecting to the FTP server. Please contact the administrator.   ');
                throw new Error('An error occurred while connecting to the FTP server. Please contact the administrator.   ');
            }

            const data = await response.json();

            setLoading(false);
            localStorage.setItem("user", data['access_token']);

            setUser(data['access_token']);
            setRole(getRole());
            setLogin(getLogin());

            callback();
        } catch (e) {
            console.error(e);
            setLoading(false);
            setError(e.message);
        }
    };


    let signOut = (callback: VoidFunction) => {
        console.log('sign out')
        setUser(null);
        localStorage.removeItem("user");
        return  callback();
    };


    const testFTPConnection = async () => {

        console.log('test ftp connection')
        try {
            const response = await fetch(`${BASE_URL}/hello-there`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include'
            });

            return response.ok;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    let value = { user,login, role, signIn, signOut, getUser, testFTPConnection };



    // useAxiosInterceptor(value, navigate, location, setSessionExpired)


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
