import React, { useContext, useEffect, useRef } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import useAuth from './useAuth';
import { ToastContext } from '../page/components/ToastProvider';

export default function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
    let navigate = useNavigate();
    let toast = useContext(ToastContext);

    const toastShown = useRef(false); // Utilisez un ref pour suivre si le toast a été affiché

    useEffect(() => {
        // Check the cookie expiration date format timestamp
          if (auth.user === null || auth.user.exp * 1000 < Date.now()) {
              console.log('user is null');
              if (!toastShown.current){
                  toast.error('Session, expired, You must be logged in to view this page.', 10);
                  toastShown.current = true;
                  auth.signOut(()=>{ navigate('/', { state: { from: location }, replace: true });});
              }
          }


        // if (auth.user === null && !toastShown.current) {
        //     toast.error('You must be logged in to view this page.');
        //     toastShown.current = true; // Mettez à jour le ref pour indiquer que le toast a été affiché
        //     navigate('/', { state: { from: location }, replace: true });
        // }
    }, [auth.user]);

    if (auth.user) {
        return children;
    } else {
        return null;
    }
}