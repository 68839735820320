import {Button, Checkbox, Form, Input} from "antd";
import {LockOutlined, UserOutlined, ArrowLeftOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import React, {useContext, useState} from "react";
import {ToastContext} from "./components/ToastProvider";


const BASE_URL = process.env.REACT_APP_BASE_URL;
const API_URL = process.env.REACT_APP_API_URL;

export default function RequestPassword(){

    const toast = useContext(ToastContext);
    const [emailSent, setEmailSent] = useState(false);
    const onFinish = async values => {
        setLoading(true);
        //trim input
        const trimmedUsername = values.username.trim();

        //sanitize input
        let body = {username:  encodeURIComponent(trimmedUsername), destination:'mastering'};

        try {

            console.log(API_URL);
            let response = await fetch(`http://localhost:444/auth/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })

            if(response.ok){
                setLoading(false);
                let data = await response.json();
                console.log(data);

                setEmailSent(true);
                toast.success(data.message);
            }else
            {
                setLoading(false);
                let data = await response.json();
                setEmailSent(false);
                toast.error(data.message);
            }

        } catch (e) {
            toast.error('Something went wrong, please try again later');
            setLoading(false);
            setEmailSent(false);
        }


    };

    let validateStatus = "";
    let helpMessage = "";
    if (emailSent) {
        validateStatus = "success";
        helpMessage = "Email has been sent!";
    }
    const [loading, setLoading] = React.useState(false);

return(
    <>

        <Form
            name="normal_login"
            className="login-form z-2"
            onFinish={onFinish}
            rootClassName={"login-form "}
        >
            <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please enter your Account id' }]}
                validateStatus={validateStatus}
                help={helpMessage}
            >
                <Input  prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Login" />
            </Form.Item>


            <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button">
                    Send request
                </Button>
            </Form.Item>
        </Form>


        <Link style={{textDecoration:'none', color:'white'}} to={"/"}> <ArrowLeftOutlined /> Back to Login</Link>
    </>

)
}