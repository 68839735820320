import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // détermine la langue du navigateur
    .use(initReactI18next) // passe i18n vers react-i18next
    .init({
        resources: {
            en: {
                translations: {
                    "welcome": "Welcome",
                    "account": "Account",
                    "login": "Log in",
                    "logout": "Log out",
                    "signup": "Sign up",
                    "home": "Home",
                    "profile": "user profile",
                    "settings": "Settings",
                    "search": "Search",
                    "rememberMe": "Remember me",
                    "forgotPassword": "Forgot password?",
                    "email": "Email",
                    "password": "Password",
                    "error": "Error",
                    "selectZIP": ".ZIP file ( max 2GB )",
                    "selectPDF": "Select your tracklist ( .PDF )",
                    "selectRef": "Select existing reference album",
                    "newRef": "Create new reference album",
                    "newRefRules": "Please input new reference album name!",
                    "upload": "Upload",
                    "loginRules": "Please input your account!",
                    'passwordRules': "Please input your Password!",
                    "loginIncorrect": "Incorrect login or password!",
                    "uploadSuccess": "Upload success!",
                    "uploadCancel": "Upload canceled!",
                    "errorOccur": "An error occurred, please try later!",
                    "fileNameRules": "File name must contain only Latin characters, numbers, spaces, periods, hyphens, and underscores!",
                    "onlyZIP": "You can only upload zip file!",
                    "onlyPDF": "You can only upload pdf file!",
                    "cancel": "Cancel upload",
                    "darkMode": "Dark mode",
                    "language": "Language",
                    "selectProject": "Select project",
                    "selectFile": "Select master.zip",
                    "uploading": "Uploading",
                    "next": "Next",
                    "previous": "Previous",
                    "selectExistingProject": "Select existing project",
                    "createNewProject": "Create new project",
                    "resetForm": "New upload",
                    "newMaster": "New master",
                    "replaceMaster": "Replace master",
                    "selectMaster": "Select your master file   ",
                    "selectTracklist": "Select your tracklist file   ",
                    "selectProjectNote": "Please note: Selecting a current project will replace all the existing files of this project.",
                    "ZipFileNote": "Ensure to include all vinyl sides of your project in a single ZIP file.",
                    "tracklistNote":"Please note that only one tracklist is allowed per project, and it must include information for all sides of the vinyl.",
                    "tracklistNote2": "It is mandatory for your tracklist to include the following elements: the artist's name and the project title. ",
                    "uploadError": "An error occurred while uploading, please try again later!",
                },
            },
            fr: {
                translations: {
                    "welcome": "Bonjour",
                    "account": "Compte client",
                    "login": "Se connecter",
                    "logout": "Se déconnecter",
                    "signup": "S'inscrire",
                    "home": "Accueil",
                    "profile": "Profil utilisateur",
                    "settings": "Paramètres",
                    "search": "Rechercher",
                    "rememberMe": "Se souvenir de moi",
                    "forgotPassword": "Mot de passe oublié?",
                    "email": "Email",
                    "password": "Mot de passe",
                    "error": "Erreur",
                    "selectZIP": "Sélectionnez votre fichier .ZIP ( max 2GB )",
                    "selectPDF": "Sélectionnez votre tracklist ( .PDF )",
                    "selectRef": "Sélectionnez un album de référence existant",
                    "newRef": "Créer un nouvel album de référence",
                    "newRefRules": "Veuillez saisir le nom du nouvel album de référence!",
                    "upload": "Télécharger",
                    "loginRules": "Veuillez saisir votre compte!",
                    "passwordRules": "Veuillez saisir votre mot de passe!",
                    "loginIncorrect": "Login ou mot de passe incorrect!",
                    "uploadSuccess": "Téléchargement réussi!",
                    "uploadCancel": "Téléchargement annulé!",
                    "errorOccur": "Une erreur s'est produite, veuillez réessayer plus tard!",
                    "fileNameRules": "Le nom du fichier ne doit contenir que des caractères latins, des chiffres, des espaces, des points, des tirets et des traits de soulignement!",
                    "onlyZIP": "Vous ne pouvez télécharger que des fichiers zip!",
                    "onlyPDF": "Vous ne pouvez télécharger que des fichiers pdf!",
                    "cancel": "Annuler le téléchargement",
                    "darkMode": "Mode sombre",
                    "language": "Langue",
                    "selectProject": "Sélectionnez un projet",
                    "selectExistingProject": "Sélectionnez un projet existant",
                    "createNewProject": "Créez-en un nouveau",
                    "selectFile": "Sélection des fichiers",
                    "uploading": "Téléchargement",
                    "next": "Suivant",
                    "previous": "Précédent",
                    "resetForm": "Nouveau téléchargement",
                    "newMaster": "Nouveau master",
                    "replaceMaster": "Remplacer un master",
                    "selectMaster": "Veuillez charger votre fichier master",
                    "selectTracklist": "Veuillez charger votre tracklist",
                    "selectProjectNote": "Veuillez noter: la sélection d'un projet en cours remplacera tous les fichiers existants de ce projet.",
                    "ZipFileNote": "Assurez-vous d'inclure toutes les faces des vinyles de votre projet dans un seul fichier ZIP.",
                    "TracklistNote": "Veuillez noter qu'une seule tracklist est autorisée par projet, et celle-ci doit comporter les informations pour toutes les faces des vinyles.\n" +
                        "Il est impératif que votre tracklist inclue les éléments suivants : le nom de l'artiste et le titre du projet.",
                    "uploadError": "Une erreur s'est produite lors du téléchargement, veuillez réessayer plus tard!",
                },


            },
            ita: {
                translations: {
                    "welcome": "Benvenuto",
                    "account": "Account",
                    "login": "Accedi",
                    "logout": "Disconnetti",
                    "signup": "Iscriviti",
                    "home": "Home",
                    "profile": "Profilo utente",
                    "settings": "Impostazioni",
                    "search": "Cerca",
                    "rememberMe": "Ricordami",
                    "forgotPassword": "Password dimenticata?",
                    "email": "Email",
                    "password": "Password",
                    "error": "Errore",
                    "selectZIP": "Seleziona il tuo file .ZIP (max 2GB)",
                    "selectPDF": "Seleziona la tua tracklist (.PDF)",
                    "selectRef": "Seleziona l'album di riferimento esistente",
                    "newRef": "Crea un nuovo album di riferimento",
                    "newRefRules": "Inserisci il nome del nuovo album di riferimento!",
                    "upload": "Carica",
                    "loginRules": "Inserisci il tuo account!",
                    "passwordRules": "Inserisci la tua password!",
                    "loginIncorrect": "Accesso o password non corretti!",
                    "uploadSuccess": "Caricamento riuscito!",
                    "uploadCancel": "Caricamento annullato!",
                    "errorOccur": "Si è verificato un errore, riprova più tardi!",
                    "fileNameRules": "Il nome del file deve contenere solo caratteri latini, numeri, spazi, punti, trattini e underscore!",
                    "onlyZIP": "Puoi caricare solo file zip!",
                    "onlyPDF": "Puoi caricare solo file pdf!",
                    "cancel": "Annulla il caricamento",
                    "darkMode": "Modalità oscura",
                    "language": "Lingua",


                },
            },
            de: {
                translations: {
                    "welcome": "Willkommen",
                    "account": "Konto",
                    "login": "Anmelden",
                    "logout": "Abmelden",
                    "signup": "Registrieren",
                    "home": "Start",
                    "profile": "Benutzerprofil",
                    "settings": "Einstellungen",
                    "search": "Suche",
                    "rememberMe": "Erinnere dich an mich",
                    "forgotPassword": "Passwort vergessen?",
                    "email": "Email",
                    "password": "Passwort",
                    "error": "Fehler",
                    "selectZIP": "Wählen Sie Ihre .ZIP-Datei aus (max 2GB)",
                    "selectPDF": "Wählen Sie Ihre Trackliste (.PDF) aus",
                    "selectRef": "Wählen Sie bestehendes Referenzalbum aus",
                    "newRef": "Erstellen Sie ein neues Referenzalbum",
                    "newRefRules": "Bitte geben Sie den Namen des neuen Referenzalbums ein!",
                    "upload": "Hochladen",
                    "loginRules": "Bitte geben Sie Ihr Konto ein!",
                    "passwordRules": "Bitte geben Sie Ihr Passwort ein!",
                    "loginIncorrect": "Anmeldung oder Passwort sind nicht korrekt!",
                    "uploadSuccess": "Hochladen erfolgreich!",
                    "uploadCancel": "Hochladen abgebrochen!",
                    "errorOccur": "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut!",
                    "fileNameRules": "Der Dateiname darf nur lateinische Buchstaben, Zahlen, Leerzeichen, Punkte, Bindestriche und Unterstriche enthalten!",
                    "onlyZIP": "Sie können nur Zip-Dateien hochladen!",
                    "onlyPDF": "Sie können nur PDF-Dateien hochladen!",
                    "cancel": "Hochladen abbrechen",
                    "darkMode": "Dunkler Modus",
                    "language": "Sprache",
                },
            },
            es: {
                translations: {
                    "welcome": "Bienvenido",
                    "account": "Cuenta",
                    "login": "Iniciar sesión",
                    "logout": "Cerrar sesión",
                    "signup": "Registrarse",
                    "home": "Inicio",
                    "profile": "Perfil de usuario",
                    "settings": "Configuraciones",
                    "search": "Buscar",
                    "rememberMe": "Recuérdame",
                    "forgotPassword": "¿Olvidaste tu contraseña?",
                    "email": "Email",
                    "password": "Contraseña",
                    "error": "Error",
                    "selectZIP": "Selecciona tu archivo .ZIP (max 2GB)",
                    "selectPDF": "Selecciona tu lista de pistas (.PDF)",
                    "selectRef": "Selecciona el álbum de referencia existente",
                    "newRef": "Crea un nuevo álbum de referencia",
                    "newRefRules": "¡Introduce el nombre del nuevo álbum de referencia!",
                    "upload": "cargar",
                    "loginRules": "¡Por favor, introduce tu cuenta!",
                    "passwordRules": "¡Por favor, introduce tu contraseña!",
                    "loginIncorrect": "¡Acceso o contraseña incorrectos!",
                    "uploadSuccess": "¡Subida exitosa!",
                    "uploadCancel": "¡Subida cancelada!",
                    "errorOccur": "Ha ocurrido un error, ¡por favor, inténtalo más tarde!",
                    "fileNameRules": "¡El nombre del archivo solo debe contener caracteres latinos, números, espacios, puntos, guiones y guiones bajos!",
                    "onlyZIP": "¡Solo puedes subir archivos zip!",
                    "onlyPDF": "¡Solo puedes subir archivos pdf!",
                    "cancel": "Cancelar subida",
                    "darkMode": "Modo oscuro",
                    "language": "Idioma",
                },
            },

        },
        fallbackLng: 'en',
        debug: true,

        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false,

        interpolation: {
            escapeValue: false, // pas besoin d'échapper à React déjà le fait en toute sécurité
        },

        react: {
            wait: true,
        },
    });

export default i18n;
